import useSize from 'ahooks/lib/useSize';
import clsx from 'clsx';
import React, {ReactNode, useState} from 'react';
import LeftAngle from '../../svgs/left-angle';
import * as styles from './index.module.scss';

export type ViewModel = {
  title: string[];
  content?: ReactNode;
}[];

type PropTypes = {
  vm: ViewModel;
};

/** 时间轴 DOM 元素宽度 */
const TIMELINE_WIDTH = 1680;

export default function SustainableDevelopment({vm}: PropTypes) {
  const historyRef = React.useRef<HTMLDivElement>(null);
  const [hoveredIndex, setHoveredIndex] = useState<null | number>(null);
  const shouldShowArrow = useHistoryScroll();
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>SUSTAINABLE DEVELOPMENT</div>
        <div className={styles.subTitle}>可持续发展</div>
      </div>
      <div className={styles.timeline}>
        {shouldShowArrow && (
          <div
            className={clsx(styles.pagination, styles.left)}
            onClick={() =>
              historyRef.current?.scrollBy({left: -240, behavior: 'smooth'})
            }
          >
            <LeftAngle />
          </div>
        )}
        <div className={styles.historyContainer} ref={historyRef}>
          <div className={styles.history}>
            {vm.map((milestone, index) => {
              // 圆四周留空的大小
              const inset = 1.5 * (vm.length - index);
              return (
                <div
                  key={index}
                  className={clsx(
                    styles.milestone,
                    hoveredIndex !== null &&
                      index !== hoveredIndex &&
                      styles.greyscale
                  )}
                  style={{
                    width: 100 / vm.length + 'px',
                  }}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  <div className={clsx(styles.historyTitle)}>
                    <div
                      className={styles[`bg${index}`]}
                      style={{
                        top: inset,
                        left: inset,
                        right: inset,
                        bottom: inset,
                      }}
                    >
                      <div>
                        {milestone.title.map(title => (
                          <div key={title}>{title}</div>
                        ))}
                      </div>
                    </div>
                  </div>
                  {milestone.content && (
                    <div
                      className={clsx(
                        styles.line,
                        index % 2 === 0 && styles.short
                      )}
                    />
                  )}
                  {milestone.content && (
                    <div className={styles.historyContent}>
                      {milestone.content}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        {shouldShowArrow && (
          <div
            className={clsx(styles.pagination, styles.right)}
            onClick={() =>
              historyRef.current?.scrollBy({left: 240, behavior: 'smooth'})
            }
          >
            <LeftAngle />
          </div>
        )}
      </div>
    </div>
  );
}

function useHistoryScroll() {
  const isSSR = typeof window === 'undefined';
  if (isSSR) return false;
  const windowSize = useSize(document.body);
  const showArrow = windowSize ? windowSize.width < TIMELINE_WIDTH : false;
  return showArrow;
}
