// extracted by mini-css-extract-plugin
export var bg0 = "index-module--bg-0--CTb56";
export var bg1 = "index-module--bg-1--m4NHa";
export var bg2 = "index-module--bg-2--AGC+0";
export var bg3 = "index-module--bg-3--MdE6Z";
export var bg4 = "index-module--bg-4--F46UI";
export var bg5 = "index-module--bg-5--DvaIA";
export var bg6 = "index-module--bg-6--njD6Q";
export var bg7 = "index-module--bg-7--Pobws";
export var bg8 = "index-module--bg-8--hh7uH";
export var container = "index-module--container--lK3Fz";
export var content = "index-module--content--8guuq";
export var greyscale = "index-module--greyscale--qfah0";
export var history = "index-module--history--xaZTH";
export var historyContainer = "index-module--history-container--YO2cm";
export var historyContent = "index-module--history-content--fIYSf";
export var historyTitle = "index-module--history-title--H6V3U";
export var left = "index-module--left--SyyFr";
export var line = "index-module--line--g3XUO";
export var milestone = "index-module--milestone--FkW0K";
export var pagination = "index-module--pagination--PriHl";
export var right = "index-module--right--gAx4h";
export var short = "index-module--short--bW6ug";
export var subTitle = "index-module--sub-title--oXx99";
export var timeline = "index-module--timeline--vo+6M";
export var title = "index-module--title--cmuR2";