import clsx from 'clsx';
import {graphql, PageProps} from 'gatsby';
import React from 'react';
import DefaultLayout from '../layouts/default';
import heroBgImage from '../images/esg-hero-bg.jpg';
import esg1Image from '../images/esg-1.png';
import esg2Image from '../images/esg-2.jpg';
import LuArticleHero from '../components/lu-article-hero';
import LuArticleToc from '../components/lu-article-toc';
import LuArticle, {ViewModel as ArticleViewModel} from '../components/article';
import SustainableDevelopment, {
  ViewModel as SustainableDevelopmentViewModel,
} from '../components/sustainable-development';
import {useTranslation} from 'react-i18next';

const ResponsibilityPage = ({data, location}: PageProps) => {
  const {t} = useTranslation('responsibility');
  const PAGE_DATA = {
    heroImage: heroBgImage,
    title: ['Social', 'Responsibility'],
    subTitle: t('社会责任'),
    tabs: [
      {
        title: t('可持续发展'),
        anchor: 'respon-1',
      },
      {
        title: t('校企合作'),
        anchor: 'respon-2',
      },
    ],
  };
  const SUSTAINABLE_DEVELOPMENT_DATA: SustainableDevelopmentViewModel = [
    {
      title: [t('2005年')],
      content: (
        <>
          <p>{t('正式启用')}</p>
          <p>{t('信息化ERP系统')}</p>
        </>
      ),
    },
    {
      title: [t('2006年')],
      content: (
        <>
          <p>{t('持续获得')}</p>
          <p>ISO9001</p>
          <p>{t('管理体系认证')}</p>
        </>
      ),
    },
    {
      title: [t('2009年')],
      content: (
        <>
          <p>{t('持续获得')}</p>
          <p>
            <b>OEKOTEX-100</b>
          </p>
          <p>Annex4 Class II</p>
          <p>{t('产品认证')}</p>
        </>
      ),
    },
    {
      title: [t('2012年')],
      content: (
        <>
          <p>
            {t('通过')}
            <b>M&S</b>
          </p>
          <p>{t('ECP验厂')}</p>
          <p>{t('2019年更新')}</p>
          <p>{t('ECP账号')}</p>
        </>
      ),
    },
    {
      title: [t('2014年')],
      content: (
        <>
          <p>{t('持续获得')}</p>
          <p>
            <b>ISO9001</b>
          </p>
          <p>
            <b>{t('品质管理体系、')}</b>
          </p>
          <p>
            <b>ISO14001</b>
          </p>
          <p>
            <b>{t('环境管理体系、')}</b>
          </p>
          <p>
            <b>OHSAS18001</b>
          </p>
          <p>
            <b>{t('职业健康管理体系认证')}</b>
          </p>
        </>
      ),
    },
    {
      title: [t('2017年')],
      content: (
        <>
          <p>
            {t('通过')}
            <b>{t('INDITEXRTM审核')}</b>
          </p>
          <p>{t('2018年通过')}</p>
          <p>
            {t('通过')}
            <b>{t('INDITEXRTMII审核')}</b>
          </p>
          <p>{t('2019年通过')}</p>
          <p>{t('GTW审核')}</p>
        </>
      ),
    },
    {
      title: [t('2018年')],
      content: (
        <>
          <p>
            <b>{t('通过VF验厂')}</b>
          </p>
          <p>
            <b>{t('成为VF集团')}</b>
          </p>
          <p>
            <b>{t('核心合作伙伴')}</b>
          </p>
          <p>-</p>
          <p>
            {t('持续参与')}
            <b>Higg FEM</b>
          </p>
          <p>{t('环境绩效审计')}</p>
          <p>-</p>
          <p>
            {t('加入')}
            <b>ZHDC</b>
          </p>
          <p>
            <b>{t('废水零排放')}</b>
            {t('计划')}
          </p>
        </>
      ),
    },
    {
      title: [t('2019年')],
      content: (
        <>
          <p>
            {t('通过')}
            <b>{t('IPE环保审核')}</b>
          </p>
          <p>{t('发布2016-2018年')}</p>
          <p>{t('PRTP报告及Detox报告')}</p>
          <p>-</p>
          <p>
            {t('通过')}
            <b>{t('GOTS体系审核')}</b>
          </p>
          <p>
            <b>{t('可开具有效染整、纺织交易证书')}</b>
          </p>
          <p>-</p>
          <p>
            {t('加入')}
            <b>{t('AIICbD节能减排')}</b>
            {t('计划')}
          </p>
        </>
      ),
    },
    {
      title: [t('持续发展'), t('永续经营')],
    },
  ];
  const ARTICLE_BODY_DATA: ArticleViewModel = {
    body: [
      {
        type: 'rich-text',
        html: (
          <div id="respon-1">
            <h3>{t('可持续发展的环保之路')}</h3>
            <p>
              {t(
                '新联和集团积极参与国家清洁生产节能减排“十二五”和“十三五”规划、“万家企业”节能减排计画。多年来新联和集团旗下的联丰染整持续对清洁能源和环保设施不断投入，保护环境，合法经营，在2017年的全国环保建设工作中获得国家环保部门高度认可。'
              )}
            </p>
            <h4>
              <li>{t('水处理系统可持续发展')}</li>
            </h4>
            <p>
              {t(
                '联丰染整拥有日处理8000吨印染污水的处理系统和日生产4000吨回用水的超滤RO膜水处理回用设施，在保证处理后污水稳定达标排放的前提下，日可用水量达12000吨，中水回用率达50%，为染整基地稳定生产提供了有力保障。'
              )}
            </p>
            <h4>
              <li>{t('定型车间废弃处理系统可持续发展')}</li>
            </h4>
            <p>
              {t(
                '联丰染整设有全覆盖定型车间废气处理系统，定型废气收集率90%以上，总颗粒物去除率80%，油烟去除率75%，废油充分回收利用。'
              )}
            </p>
            <h4>
              <li>{t('锅炉烟尘处理系统可持续发展')}</li>
            </h4>
            <p>
              {t(
                '联丰染整设有锅炉反硝化静电除尘处理系统，保证锅炉大气排放达标。'
              )}
            </p>
          </div>
        ),
      },
      {
        type: 'custom',
        component: <SustainableDevelopment vm={SUSTAINABLE_DEVELOPMENT_DATA} />,
      },
      {
        type: 'rich-text',
        html: (
          <div id="respon-2">
            <h3>{t('校企合作')}</h3>
            <p>
              {t(
                '新联和作为区域内领先的综合纺织集团，积极加强与当地高校的联系，展开多元化的合作。'
              )}
            </p>
            <p>
              {t(
                '自挂牌“常州大学产学研实践示范基地”以来，已接纳多批社会实践学生到公司实习，为在校生提供一个了解行业，发现自我的平台。'
              )}
            </p>
          </div>
        ),
      },
      {
        type: `image-group`,
        images: [esg1Image],
        caption: t(
          '新联和集团总经理陈晓源先生参加福州外语外贸学院学生毕业联展'
        ),
      },
      {
        type: `image-group`,
        images: [esg2Image],
        caption: t('“极光杯”参赛学员合影'),
      },
      {
        type: 'rich-text',
        html: (
          <>
            <p>
              {t(
                '同时多次举办校内讲座、与周边院校教师互访，为相关专业学科提供实践平台。并举办“极光杯”比赛，让学生在实习工作中，将实践与知识深度结合。'
              )}
            </p>
            <p>
              {t(
                '通过比赛，新联和集团品牌部为参与实习的同学们提供了展现自我的机会，丰富了课余生活，同时帮助同学发掘自身特长，提前熟悉职场工作环境，培养正确的工作理念，也明确了就业的方向和目标，真正实现了校企合作的意义！'
              )}
            </p>
          </>
        ),
      },
    ],
  };
  return (
    <DefaultLayout location={location}>
      <LuArticleHero
        bgImageUrl={PAGE_DATA.heroImage}
        title={PAGE_DATA.title}
        subTitle={PAGE_DATA.subTitle}
      />
      <LuArticleToc tabs={PAGE_DATA.tabs} />
      <LuArticle vm={ARTICLE_BODY_DATA} />
    </DefaultLayout>
  );
};

export default ResponsibilityPage;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
